<template>
    <Error>
        <template v-slot:code>
            404
        </template>
        <template v-slot:description> 
            Упс, такої сторінки не існує
        </template>

    </Error>
</template>


<script>
import Error from "./ErrorTemplate.vue"
export default {
    components: { Error }
}
</script>
