<template>
  <section class="constructor__objects">
    <h2 class="subtitle constructor__objects-title">
      оберіть тип об'єкту
      <span v-if="error.check && error.fields.includes(error.field)" class="errorStar">*</span>
    </h2>
    <ul class="typelist">
      <li
        v-for="object in objects"
        @click="$emit('input', object.reducer)"
        :key="object.title"
        class="typelist__item"
        :class="{
          'typelist__item--active': object.reducer === modelObject,
        }"
      >
        <Object :type="object.title" />
        <span class="subtitle typelist__subtitle">{{ object.title }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
import Object from "@/components/Object";
export default {
  model: {
    prop: "modelObject",
  },
  props: ["error", "modelObject"],
  components: { Object },
  data() {
    return {
      objects: [
        { title: "квартира", reducer: "Квартира" },
        { title: "будинок", reducer: "Будинок" },
        {
          title: "кафе або ресторан (HoReCa)",
          reducer: "Кафе або ресторан (HoReCa)",
        },
        { title: "МАФ або магазин", reducer: "МАФ або магазин" },
        {
          title: "офісне або складське приміщення",
          reducer: "Офісне або складське приміщення",
        },
        { title: "інший тип об'єкту", reducer: "Інший тип об'єкту" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_main.scss";

.constructor {
  &__objects {
    margin-bottom: 24px;
    padding: 48px 40px 20px;
    padding-top: 48px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;

    &-title {
      margin-bottom: 40px;
    }
    &__payment {
      margin-bottom: 56px;
      padding: 56px 48px 64px 56px;
      border: 1px solid $secondaryColor;
      border-radius: $borderRadius;

      &-title {
        margin-bottom: 56px;
      }

      &-group {
        display: flex;
        justify-content: space-between;
        &-item {
          max-width: 330px;
          width: 100%;
        }
      }
    }
  }
}
</style>