<template>
  <section v-if="subscriptionDuration.length" class="constructor__subscription">
    <h2 class="subtitle constructor__subscription-title">
      оберіть кількість місяців
      <span v-if="error.check && error.fields.includes(error.field)" class="errorStar">*</span>
    </h2>
    <ul class="constructor__subscription-list">
      <li
        class="constructor__subscription-list-item"
        v-for="subscription in subscriptionDuration"
        :key="subscription.id"
        @click="leadInfo.month = subscription"
        :class="{
          'constructor__subscription-list-item--active':
            leadInfo.month && subscription.duration === leadInfo.month.duration,
        }"
      >
        <div class="period">
          <p>
            {{ subscription.duration }}
            {{ declensionMonth(subscription.duration) }}
          </p>
          <Switcher
            :choice="
              leadInfo.month &&
              subscription.duration === leadInfo.month.duration
            "
          />
        </div>
        <div class="price">
          <span>{{ subscription.price }} ₴</span>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { getPackagesWithDurations, declensionMonth} from "@/mixins/API";
import Switcher from "@/components/Switch";

export default {
  mixins: [getPackagesWithDurations, declensionMonth],
  model: {
    prop: "modelMonth",
  },
  components: {
    Switcher,
  },
  props: ["modelMonth", "leadInfo", 'error'],

};
</script>

<style lang="scss">
@import "@/assets/styles/main";
.constructor {
  &__subscription {
    margin-bottom: 24px;
    padding: 56px 0 72px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;
    &-title {
      margin-bottom: 56px;
    }
    &-list {
      padding: 0 40px;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(4, 260px);
      justify-content: center;
      @include screen(1208px) {
        grid-template-columns: repeat(2, 260px);
      }
      @include screen(768px) {
        grid-template-columns: 260px;
      }
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: 23px;
        padding-right: 27px;
        padding-left: 31px;
        font-size: 24px;
        line-height: 31px;
        color: $secondaryColor;
        border: 1px solid $secondaryColor;
        border-radius: 38px;
        height: 144px;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        &:hover {
          border-color: $mainColor;
          color: $mainColor;
        }
        &--active {
          background-color: $mainColor;
          color: $darkColor !important;
        }
        &:hover {
          .switch {
            border-color: $mainColor;
            background-color: $darkColor;
            &__circle {
              border-color: $mainColor;
            }
          }
        }
        .period {
          display: flex;
          justify-content: space-between;
        }
        .price {
          font-size: 48px;
          line-height: 62px;
        }
      }
    }
  }
}
</style>