<template>
  <input
    type="text"
    v-model.trim="input"
    @input="(event) => $emit('input', event.target.value)"
    class="package__contacts-input"
    :class="{ errorBorder: error.check && error.fields.includes(error.field) }"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  model: {
    prop: "modelInput",
  },
  props: ["modelInput", "error", "placeholder"],
  data() {
    return {
      input: "",
    };
  },
};
</script>

<style>
.errorBorder {
  border-color: red !important;
}
</style>