<template>
  <section class="constructor__info">
    <h2 class="subtitle constructor__info-title">
      заповніть інформацію, будь ласка
      <span
        v-if="
          (error.check && lead.phone.length < 13 && lead.installedEquipment) ||
          errorStatus
        "
        class="errorStar"
        >*</span
      >
    </h2>
    <div class="constructor__info-inputs">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: ["error", "lead"],
  data() {
    return {
      test: "",
    };
  },
  computed: {
    errorStatus() {
      return this.error.field.some(
        (el) => this.error.check && this.error.fields.includes(el)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_main.scss";

.constructor {
  &__info {
    margin-bottom: 24px;
    padding: 40px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;
    @include screen(768px) {
      padding: 20px;
    }

    &-title {
      margin-bottom: 40px;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &-input {
      max-width: 620px;
      width: 100%;
      padding: 16px 0 16px 56px;
      border: 1px solid $secondaryColor;
      border-radius: 30px;
      background: none;
      color: $mainColor;
      font-size: 24px;
      line-height: 31px;
      @include screen(456px) {
        font-size: 16px;
        line-height: 18px;
        padding-left: 16px;
      }

      &::placeholder {
        font-size: 24px;
        line-height: 31px;
        @include screen(456px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>