<template>
  <section class="constructor__equipment">
    <h2 class="subtitle constructor__equipment-title">
      чи є у вас вже встановленне охоронне обладнання?
    </h2>
    <v-select
      :options="[
        { label: 'Так', status: true },
        { label: 'Ні', status: false },
      ]"
      :reduce="(option) => option.status"
      placeholder="зробіть вибір"
      :searchable="false"
      :clearable="false"
      class="constructor__select"
      @input="(status) => $emit('input', status)"
    ></v-select>
  </section>
</template>

<script>
export default {
  model: {
    prop: "modelEquipment",
  },
  props: ["modelEquipment"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_main.scss";

.constructor {
  &__equipment {
    margin-bottom: 24px;
    padding: 40px 20px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;

    &-title {
      margin-bottom: 24px;
    }

    select {
      display: flex;
      margin: 0 auto;
    }
  }
}
</style>