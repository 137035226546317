<template>
  <div class="package__contacts-input--wrapper">
    <input
      type="text"
      class="package__contacts-input"
      @input="(event) => $emit('input', event.target.value)"
      :class="{ errorBorder: error.check && error.fields.includes(error.field) }"
      :placeholder="placeholder"
    />
    <i :class="icon"></i>
  </div>
</template>

<script>
export default {
  model: {
    prop: "modelAddress",
  },
  props: ["modelAddress", "icon", 'error', "placeholder"],
};
</script>

<style>
</style>