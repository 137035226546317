<template>
  <input
    :type="type"
    class="constructor__info-input"
    :placeholder="placeholder"
    :value="input"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  model: {
    prop: "modelValue",
  },
  props: {
    modelValue: String,
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      input: "",
    };
  },
};
</script>

<style>
</style>