<template>
  <section class="constructor__city">
    <h2 class="subtitle constructor__city-title">
      оберіть місто, будь ласка
      <span
        v-if="error.check && error.fields.includes(error.field)"
        class="errorStar"
        >*</span
      >
    </h2>
    <v-select
      v-if="cities.length"
      label="name"
      :options="cities"
      placeholder="оберіть місто з переліку"
      @input="(city) => $emit('input', city)"
      class="constructor__select"
      :searchable="false"
      :clearable="false"
    ></v-select>
    <Spinner v-else />
  </section>
</template>

<script>
import Spinner from "@/components/Spinner";
import { getCities } from "@/mixins/API.js";
export default {
  model: {
    prop: "modelCity",
  },
  props: ["modelCity", "check", "error"],
  mixins: [getCities],
  components: { Spinner },
};
</script>

<style lang="scss">
@import "@/assets/styles/_main.scss";

.constructor {
  &__city {
    margin-bottom: 24px;
    padding: 40px 0;
    border: 1px solid $secondaryColor;
    border-radius: 40px;
    @include screen(768px) {
      padding: 40px 20px;
    }
    &-title {
      margin-bottom: 24px;
    }
  }
  &__select {
    max-width: 620px;
    margin: 0 auto;

    & .vs__dropdown-toggle {
      transition: all 0.4s ease;
      padding: 11px 50px;
      border: 2px solid $secondaryColor;
      border-radius: 30px;
      font-size: 24px;
      line-height: 31px;
      @include screen(488px) {
        padding: 11px 20px;
      }
    }

    & .vs__open-indicator {
      fill: $mainColor;
    }

    & .vs__search {
      @include screen(488px) {
        font-size: 10px;
        line-height: 20px;
        height: 34px;
      }
    }

    & .vs__search,
    & .vs__search:focus {
      color: $mainColor;
      padding: 0;
      margin: 0;
    }

    & .vs__search::placeholder {
      border: none;
      font-size: 24px;
      line-height: 31px;
      color: $secondaryColor;
      @include screen(480px) {
        font-size: 16px;
      }
    }

    & .vs__selected {
      margin: 0;
      padding: 0;
      color: $mainColor;
      border: none;
    }

    & .vs__dropdown-menu {
      padding-top: 15px;
      padding-bottom: 0;
      border: 1px solid $secondaryColor;
      background-color: transparent;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    & .vs__dropdown-option {
      padding-left: 50px;
      font-size: 24px;
      line-height: 31px;
      color: $secondaryColor;
      background-color: $darkColor;
    }

    & .vs__dropdown-option--highlight {
      background-color: $mainColor;
      color: $darkColor;
    }
  }
}
</style>