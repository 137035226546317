<template>
  <li class="constructor__brand-item">
    <div class="circle" :class="{ active: selectedItem === brand.reducer }">
      <img
        :src="require(`@/assets/images/brand${getImage}.png`)"
        alt="бренд1"
      />
    </div>
    <p
      class="constructor__brand-name"
      :class="{ activeName: selectedItem === brand.reducer }"
    >
      {{ brand.title }}
    </p>
  </li>
</template>

<script>
export default {
  props: ['brand', 'selectedItem'],
  computed: {
    getImage() {
      return this.brand.title !== 'Інше' ? this.brand.title : 'Unknown';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_main.scss';
.constructor {
  &__brand {
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 180px;
      width: 100%;
      color: $secondaryColor;
      @include screen(768px) {
        width: 80px;
      }
      cursor: pointer;
      .active {
        background-color: $mainColor;
      }
      .activeName {
        color: $mainColor;
      }
      &:hover .constructor__brand-name {
        color: $mainColor;
      }
      &:hover .circle {
        border-color: $mainColor;
      }
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
        width: 160px;
        height: 160px;
        border: 1px solid $secondaryColor;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        &:hover {
          border-color: $mainColor;
        }
        @include screen(768px) {
          width: 80px;
          height: 80px;
          img {
            width: 50px;
          }
        }
      }
    }
    &-name {
      font-size: 24px;
      line-height: 31px;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
