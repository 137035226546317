<template>
  <div class="package__contacts">
    <h2 class="subtitle package__contacts-title">контактна інформація</h2>
    <div class="package__contacts-wrapper">
      <slot></slot>
    </div>
    <h2 class="subtitle package__contacts-title">адреса</h2>
    <div class="package__contacts-wrapper package__contacts-wrapper--address">
      <slot name="address"></slot>
    </div>
    <h2 class="subtitle package__contacts-title">
      оберіть зручний день та проміжок часу для підключення
    </h2>
    <div class="package__contacts-wrapper package__contacts-wrapper--last">
      <slot name="date"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/styles/main";
.mx {
  &-datepicker {
    width: 100%;
  }
  &-input {
    background-color: transparent;
    border-color: $secondaryColor;
    border-radius: 38px;
    height: 58px;
    padding: 14px 13px 11px 24px;
    cursor: pointer;
    color: $mainColor;
    &,
    &::placeholder {
      font-family: $mainFont;
      font-size: 18px;
      line-height: 31px;
      @include screen(980px) {
        font-weight: bold;
        font-size: 14px;
      }
    }
    &:hover {
      border-color: $secondaryColor;
    }
    &:focus {
      border-color: #fce500;
    }
  }
  &-time-content {
    border-bottom: 5px solid;
  }
  &-icon-calendar {
    font-size: 24px;
    right: 16px;
    color: $mainColor;
  }
}

.package {
  &__contacts {
    padding-top: 56px;
    &-title {
      font-size: 24px;
      line-height: 31px;
      font-weight: 400;
    }
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
      padding: 32px 0;
      @include screen(980px) {
        flex-direction: column;
        align-items: center;
      }
      &--last {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        padding-bottom: 56px;
      }
      &--address {
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        justify-content: center;
        @include screen(980px) {
          // flex-direction: column;
          align-items: start;
        }
      }
      gap: 20px;
    }
    &-input {
      max-width: 580px;
      width: 100%;
      padding: 14px 13px 11px 24px;
      border: 1px solid $secondaryColor;
      background-color: transparent;
      border-radius: 30px;
      font-size: 18px;
      line-height: 31px;
      color: $mainColor;
      caret-color: $secondaryColor;
      @include screen(980px) {
        max-width: 100%;
      }
      &:focus {
        outline: 1px solid $mainColor;
      }
      &::placeholder {
        font-family: $mainFont;
        font-size: 18px;
        line-height: 31px;
        @include screen(980px) {
          font-weight: bold;
          font-size: 14px;
        }
      }
      &--wrapper {
        position: relative;
        i {
          font-size: 18px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
        }
      }
    }
    &-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
      margin: 0 auto;
      max-width: 400px;
      width: 100%;
      background-color: transparent;
      border: 1px solid $secondaryColor;
      border-radius: 57px;
      font-size: 30px;
      line-height: 30px;
      color: $secondaryColor;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      @include screen(768px) {
        max-width: 100%;
      }
      @include screen(458px) {
        padding: 15px 0;
      }
      &--active {
        color: $darkColor;
        background-color: $mainColor;
        border-color: transparent;
      }
    }
  }
}
</style>