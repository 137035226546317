import Vue from 'vue';
import Router from 'vue-router';
import NotFound from './components/Errors/404.vue';
import Constructor from './pages/Constructor';
import Package from './pages/Package';
import Completion from './pages/Completion';
import SuccessPayment from './pages/Completion/SuccessPayment.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      component: NotFound,
      name: 'not-found',
    },
    {
      path: '/',
      component: Constructor,
      name: 'constructor',
    },
    {
      path: '/completion',
      component: Completion,
      name: 'completion',
    },
    {
      path: '/success-payment',
      component: SuccessPayment,
      name: 'successPayment',
    },
    {
      path: '/package',
      component: Package,
      name: 'package',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
