<template>
  <p class="errorText">
    *Не всі поля заповнено
  </p>
</template>

<script>
export default {};
</script>

<style>
.errorText {
  font-size: 24px;
  color: gainsboro;
  text-align: center;
  padding-bottom: 24px;
}
</style>