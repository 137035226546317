<template>
  <main class="package">
    <div class="container">
      <h1 class="title package__title">
        ви обрали наступний пакет підключення
      </h1>
      <Plan :packageInfo="packageInfo" />
      <LeadContacts>
        <LeadInput
          :placeholder="`ваше ім'я`"
          v-model="leadInfo.name"
          @keypress.native="regExp($event, '[А-Яа-яєії\\s]')"
          :error="{ check, fields, field: 'name' }"
        />
        <MaskedInput
          class="package__contacts-input"
          :class="{
            errorBorder: check && fields.includes('phone'),
          }"
          v-model="leadInfo.phone"
          mask="\+\381111111111"
          placeholder="ваш номер телефону"
        />
        <LeadInput
          :placeholder="'електронна пошта'"
          v-model="leadInfo.email"
          :error="{ check, fields, field: 'email' }"
        />
        <LeadInput
          :placeholder="'кодове слово'"
          v-model="leadInfo.code"
          @keypress.native="regExp($event, '[А-Яа-яєії\\s]')"
          :error="{ check, fields, field: 'code' }"
        />
        <LeadInput
          :placeholder="'ПІБ довіреної особи'"
          v-model="leadInfo.trustedName"
          @keypress.native="regExp($event, '[А-Яа-яєії\\s]')"
          :error="{ check, fields, field: 'trustedName' }"
        />
        <MaskedInput
          class="package__contacts-input"
          :class="{
            errorBorder: check && fields.includes('trustedPhone'),
          }"
          v-model="leadInfo.trustedPhone"
          mask="\+\381111111111"
          placeholder="номер телефону довіреної особи"
        />
        <template #address>
          <LeadAddress
            :placeholder="'вулиця'"
            :icon="'fas fa-road'"
            v-model="leadInfo.address"
            @keypress.native="regExp($event, '\\D')"
            :error="{ check, fields, field: 'address' }"
          />
          <LeadAddress
            :placeholder="'будинок'"
            :icon="'fas fa-home'"
            v-model="leadInfo.addressNumber"
            :error="{ check, fields, field: 'addressNumber' }"
          />
          <LeadAddress
            v-if="packageInfo.typeObject === 'Квартира'"
            :placeholder="'квартира'"
            :icon="'far fa-building'"
            :error="{ check, fields, field: 'flat' }"
            v-model="leadInfo.flat"
          />
        </template>
        <template #date>
          <DatePicker
            v-model="leadInfo.date"
            valueType="format"
            :lang="lang"
            :format="'DD.MM.YYYY'"
            :range-separator="'T'"
            :disabled-date="disabledCalendarDates"
          >
            <template #input>
              <input
                class="mx-input"
                :class="{ errorBorder: check && fields.includes('date') }"
                placeholder="оберіть дату"
                type="text"
                :value="leadInfo.date"
                readonly
              />
            </template>
          </DatePicker>
          <DatePicker
            v-model="leadInfo.timeInterval"
            :minute-step="30"
            :disabled="!leadInfo.date"
            :time-picker-options="{
              start: startTime,
              step: '01:00',
              end: '19:00',
              format: 'HH:mm',
            }"
            format="HH:mm"
            value-type="format"
            type="time"
            range
            :range-separator="'-'"
          >
            <template #input>
              <input
                class="mx-input"
                :class="{
                  errorBorder: check && fields.includes('timeInterval'),
                }"
                placeholder="оберіть проміжок часу"
                type="text"
                :value="
                  leadInfo.timeInterval && leadInfo.timeInterval.join('-')
                "
                readonly
              /> </template
          ></DatePicker>
        </template>
      </LeadContacts>
      <Payment
        @onError="text = check = true"
        v-model="type"
        :fields="fields"
        :packageInfo="packageInfo"
        :leadInfo="leadInfo"
      />
      <Error v-if="text" />
    </div>
  </main>
</template>

<script>
import "vue2-datepicker/locale/uk";
import "vue2-datepicker/index.css";
import Plan from "./Plan";
import LeadContacts from "./LeadContacts";
import LeadInput from "./LeadContacts/LeadInput.vue";
import MaskedInput from "vue-masked-input";
import LeadAddress from "./LeadContacts/LeadAddress.vue";
import DatePicker from "vue2-datepicker";
import Payment from "./Payment";
import Error from "./Error";
import { validEmail } from "@/mixins/helpers/validEmail";
import { regExp } from "@/mixins/helpers/regularExpression";
import { dateTime } from "@/mixins/helpers/dateTime";
import { checkout } from "@/mixins/API";
export default {
  name: "Package",

  mixins: [regExp, validEmail, checkout, dateTime],
  components: {
    Plan,
    DatePicker,
    LeadContacts,
    LeadInput,
    LeadAddress,
    MaskedInput,
    Payment,
    Error,
  },

  created() {
    this.packageInfo = JSON.parse(localStorage.getItem("packageInfo"));
    if (this.packageInfo.typeObject !== "Квартира") {
      delete this.leadInfo.flat;
    }
    this.fields = Object.keys(this.leadInfo);
  },
  data() {
    return {
      packageInfo: null,
      leadInfo: {
        name: "",
        phone: "",
        email: "",
        address: "",
        addressNumber: "",
        flat: "",
        trustedName: "",
        trustedPhone: "",
        code: "",
        date: "",
        timeInterval: [],
      },
      fields: [],
      check: false,
      text: false,
      type: "",
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  watch: {
    leadInfo: {
      handler() {
        this.leadInfo.phone = this.leadInfo.phone.replace(/_/g, '');
        this.leadInfo.trustedPhone = this.leadInfo.trustedPhone.replace(/_/g, '');
        const fieldsToValidate = Object.entries(this.leadInfo).filter(
          ([key, value]) =>
            (key.toLowerCase().includes("phone") && value.length !== 13) ||
            (key === "email" && this.validEmail(value)) ||
            !value.length
        );
        this.fields = fieldsToValidate.map(([key]) => key);
        if (!this.fields.length) {
          this.text = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_main.scss";
.package {
  padding-bottom: 112px;
  @include screen(767px) {
    padding-bottom: 72px;
  }
}
</style>
