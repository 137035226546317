export const getPackageImage = {
  methods: {
    getPackageImage(packageName, color) {
      switch (packageName) {
        case "максимальный захист":
          return color === "white"
            ? require("@/assets/images/whiteMAX.png")
            : require("@/assets/images/blackMAX.png");
        case "базовий захист":
          return color === "white"
            ? require("@/assets/images/whiteSTART.png")
            : require("@/assets/images/blackSTART.png");
      }
    },
  },
};
