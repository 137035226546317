<template>
  <div class="switch" :class="{ 'switch--switched': choice }">
    <div
      class="switch__circle"
      :class="{ 'switch__circle--switched': choice }"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['choice'],
};
</script>

<style lang="scss">
@import '@/assets/styles/_main.scss';

.switch {
  cursor: pointer;
  width: 55px;
  height: 24px;
  background-color: $secondaryColor;
  border: 1px solid $secondaryColor;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $darkColor;
    border-color: $mainColor;

    .switch__circle {
      background-color: $darkColor;
      border-color: $mainColor;
    }
  }

  &--switched {
    background-color: $mainColor;
  }

  &__circle {
    width: 16px;
    height: 16px;
    background-color: $darkColor;
    position: absolute;
    border: 1px solid transparent;
    border-radius: 30px;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    transition: left 0.7s ease;
    &--switched {
      left: 60%;
    }
  }
}
</style>
