export const regExp = {
  methods: {
    regExp(e, regExp) {
      if (!RegExp(`${regExp}`).test(e.key)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
  }
}