import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from "axios";
import vSelect from 'vue-select';

axios.defaults.headers.common = { 'Authorization': `Bearer 2|tOemLveK1PrPG7Eh0EfieQjAzDuR4Ov5RvdgU8zM` }

import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

Vue.prototype.axios = axios;
Vue.prototype.$domain = 'https://back-interactive.sheriff.com.ua/api';
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

