<template>
  <ul class="constructor__brand-list">
    <BrandItem
      v-for="brand in brands"
      :key="brand.title"
      :selectedItem="selectedBrand"
      :brand="brand"
      @click.native="$emit('onUpdateBrand', brand)"
    />
  </ul>
</template>

<script>
import BrandItem from "./BrandItem.vue";
export default {
  components: { BrandItem },
  props: ["brands", "selectedBrand"],
};
</script>

<style lang="scss">
@import "@/assets/styles/main";
.typelist__item--active {
  border-color: none;
  transition: all 0.4s ease;
  .typelist__circle {
    background-color: $mainColor;
  }
  svg path {
    fill: $darkColor;
  }
  .subtitle {
    color: $mainColor;
  }
}
.constructor {
  .typelist {
    display: grid;
    align-items: start;
    justify-content: center;
    max-width: 100%;
    grid-template-columns: repeat(6, 1fr);
    @include screen(1180px) {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 30px;
    }
    @include screen(768px) {
      justify-items: center;
      gap: 15px;
      grid-template-columns: repeat(3, 1fr);
    }

    &__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 160px;
      margin-bottom: 32px;
      border: 1px solid $secondaryColor;
      border-radius: 50%;
      @include screen(768px) {
        width: 80px;
        height: 80px;
        svg {
          width: 50px;
          height: 50px;
        }
      }

      .typelist__item--active {
        border-color: none;
      }
    }
    &__subtitle {
      transition: all 0.3s ease-in-out;
      @include screen(768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 330px;
      width: 100%;
      justify-content: center;
      text-align: center;
      color: $secondaryColor;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      @include screen(768px) {
        width: 80px;
      }

      svg path {
        transition: fill 0.3s ease-in-out;
      }
      &.typelist__item--active:hover {
        svg path {
          fill: $darkColor;
        }
      }
      &:hover {
        border-color: $mainColor;
        .typelist__subtitle {
          color: $mainColor;
        }
        .typelist__circle {
          border-color: $mainColor;
        }

        svg path {
          fill: $mainColor;
        }
      }
    }
  }
}
</style>
