<template>
  <main class="constructor">
    <div class="container">
      <h1 class="title constructor__title">конструктор підключення</h1>
      <Cities
        v-model="leadInfo.city"
        :error="{ check, fields, field: 'city' }"
      />
      <Objects
        v-model="leadInfo.typeObject"
        :error="{ check, fields, field: 'typeObject' }"
      />
      <Choice v-model="leadInfo.installedEquipment" />
      <template v-if="leadInfo.installedEquipment">
        <Brands
          :error="{ check, fields, field: 'existBrand' }"
          v-model="leadInfo.existBrand"
        />
        <Contacts
          :lead="leadInfo"
          :error="{ check, fields, field: ['name', 'phone'] }"
        >
          <ContactInput
            v-model="leadInfo.name"
            :type="'text'"
            :placeholder="`ваше ім'я`"
            @keypress.native="regExp($event, '\\D')"
          />
          <MaskedInput
            class="constructor__info-input"
            v-model="leadInfo.phone"
            mask="\+\381111111111"
            placeholder="номер телефону"
          />
        </Contacts>
        <Summary v-model="check" :leadInfo="leadInfo" :fields="fields" />
      </template>
      <template v-else-if="leadInfo.installedEquipment !== null">
        <Contacts
          :lead="leadInfo"
          :error="{
            check,
            fields,
            field: ['totalFloor', 'floor', 'roomsWithWindows'],
          }"
        >
          <ContactInput
            v-model="leadInfo.totalFloors"
            :placeholder="'поверховність будинку'"
            v-mask="'###'"
          />
          <ContactInput
            v-model="leadInfo.floor"
            v-mask="'###'"
            :placeholder="'ваш поверх'"
          />
          <ContactInput
            v-mask="'##'"
            v-model="leadInfo.roomsWithWindows"
            :placeholder="'кількість приміщень з вікнами'"
          />
        </Contacts>
        <Package
          v-model="leadInfo.selectedPlan"
          :leadInfo="leadInfo"
          :error="{ check, fields, field: 'selectedPlan' }"
        />
        <Duration
          v-model="leadInfo.month"
          :leadInfo="leadInfo"
          :error="{ check, fields, field: 'month' }"
        />
        <Summary
          v-model="check"
          :nextpage="true"
          :leadInfo="leadInfo"
          :fields="fields"
        />
      </template>
    </div>
  </main>
</template>

<script>
import Cities from "./Cities";
import Objects from "./Objects";
import Choice from "./Choice";
import Brands from "./Brands";
import Contacts from "./Contacts";
import ContactInput from "./Contacts/ContactInput.vue";
import Package from "./PackageOffer";
import Duration from "./Duration";
import Summary from "./Summary";
import MaskedInput from "vue-masked-input";

import { regExp } from "@/mixins/helpers/regularExpression.js";

export default {
  mixins: [regExp],
  components: {
    MaskedInput,
    Cities,
    Objects,
    Choice,
    Brands,
    Contacts,
    ContactInput,
    Package,
    Duration,
    Summary,
  },
  name: "Constructor",
  data() {
    return {
      leadInfo: {
        name: "",
        phone: "",
        city: null,
        selectedPlan: null,
        month: null,
        typeObject: "",
        existBrand: "",
        totalFloors: "",
        floor: "",
        roomsWithWindows: "",
        installedEquipment: null,
      },
      fields: [],
      text: false,
      check: false,
    };
  },
  watch: {
    leadInfo: {
      handler() {
        let dropKeys;
        this.leadInfo.installedEquipment === true
          ? (dropKeys = [
              "floor",
              "totalFloors",
              "roomsWithWindows",
              "month",
              "installedEquipment",
              "selectedPlan",
            ])
          : (dropKeys = ["name", "phone", "installedEquipment", "existBrand"]);
        const fieldsToValidate = Object.entries(this.leadInfo).filter(
          ([key, value]) => {
            if (
              !dropKeys.includes(key) &&
              (value === null || !Object.keys(value).length)
            ) {
              return true;
            }
            return false;
          }
        );
        this.fields = fieldsToValidate.map(([key]) => key);
        if (
          this.leadInfo.totalFloors &&
          +this.leadInfo.floor > +this.leadInfo.totalFloors
        ) {
          this.fields.push("floor");
        }
        this.leadInfo.phone = this.leadInfo.phone.replace(/_/g, "");
        if (
          this.leadInfo.phone.length === 13 &&
          !this.fields.length
        ) {
          this.check = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main";
.constructor {
  padding-bottom: 70px;
  &__title {
    margin-bottom: 72px;
  }
}
.errorStar {
  color: red;
}
</style>
