<template>
  <svg
    width="128"
    height="120"
    viewBox="0 0 128 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M83.3474 68.7202L110.424 105.696L90.7346 120L63.6522 83.0177L36.5759 120L16.8807 105.696L43.957 68.7202L0 54.7679L7.73518 31.3928L51.3442 45.6964V0H75.9601V45.6964L119.569 31.3928L127.304 54.7679L83.3474 68.7202Z"
      fill="#121212"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>