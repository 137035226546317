<template>
  <section class="constructor__brand">
    <h2 class="subtitle constructor__brand-title">
      оберіть марку встановленного обладнання
      <span
        v-if="error.check && error.fields.includes(error.field)"
        class="errorStar"
        >*</span
      >
    </h2>
    <BrandsList
      :brands="brands"
      @onUpdateBrand="(brand) => $emit('input', brand.reducer)"
      :selectedBrand="modelBrand"
    />
  </section>
</template>

<script>
import BrandsList from "@/components/Brands/index.vue";
export default {
  components: {
    BrandsList,
  },
  model: {
    prop: "modelBrand",
  },
  props: ["error", "modelBrand"],
  data() {
    return {
      brands: [
        { title: "Ajax", reducer: "Ajax" },
        { title: "Lun", reducer: "Лунь" },
        { title: "Orion", reducer: "Оріон" },
        { title: "Maks", reducer: "МАКС" },
        { title: "Dunai", reducer: "Дунай" },
        { title: "Інше", reducer: "Інше" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_main.scss";
.constructor {
  &__brand {
    margin-bottom: 24px;
    padding: 40px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;

    &-title {
      margin-bottom: 40px;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      justify-items: center;
      gap: 16px;
      // @include screen(768px) {
      //   grid-template-columns: repeat(3, 80px);
      // }
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      @include screen(768px) {
        width: 80px;
      }
    }

    &-name {
      font-size: 24px;
      line-height: 31px;
      color: $secondaryColor;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>