<template>
  <section class="constructor__total">
    <h2 v-if="nextpage" class="subtitle constructor__total-title">
      загальна сума підключення
    </h2>
    <div class="constructor__total-content">
      <SheriffStar />
      <template v-if="!nextpage">
        <button
          @click="makeLead(leadInfo)"
          v-if="!check || (leadInfo.phone.length === 13 && fields.length === 0)"
          class="constructor__total-submit"
        >
          залишити заявку
        </button>
        <p v-else class="constructor__total-error">Не всі поля заповнені</p>
      </template>
      <template v-else>
        <p
          v-if="!fields.length || !check"
          :class="{
            'constructor__total-price--empty':
              !leadInfo.selectedPlan || !leadInfo.month,
          }"
          class="constructor__total-price"
        >
          {{ getTotal }}
        </p>
        <p v-else class="constructor__total-error">Не всі поля заповнені</p>
      </template>
      <SheriffStar />
    </div>
    <button
      @click="generatePackage(leadInfo)"
      v-if="nextpage && (!check || fields.length === 0)"
      class="constructor__total-submit"
    >
      залишити заявку
    </button>
  </section>
</template>

<script>
import SheriffStar from "@/components/SheriffStar";
import { makeLead, generatePackage } from "@/mixins/API";
import { floorPrice } from "@/mixins/helpers/floorPrice";
export default {
  model: {
    prop: "modelCheck",
  },
  props: ["modelCheck", "leadInfo", "fields", "nextpage"],
  mixins: [makeLead, generatePackage, floorPrice],
  components: {
    SheriffStar,
  },
  updated() {
    this.$emit("input", this.check);
  },
  data() {
    return {
      check: false,
    };
  },
  computed: {
    getTotal() {
      if (this.leadInfo.selectedPlan !== null && this.leadInfo.month !== null) {
        const rooms = +this.leadInfo.roomsWithWindows || 2;

        const hubPriceWithMontage =
          this.leadInfo.selectedPlan.hub_price +
          this.leadInfo.selectedPlan.hub_montage_price;

        const detectorsPriceWithMontage =
          this.leadInfo.selectedPlan.detector_price * rooms +
          this.leadInfo.selectedPlan.detector_montage_price * rooms;

        const abonPrice =
          Math.floor(
            (this.leadInfo.month.price +
              this.leadInfo.month.duration *
                (rooms * this.leadInfo.selectedPlan.detector_abon_price)) /
              this.leadInfo.month.duration
          ) * this.leadInfo.month.duration;

        switch (this.leadInfo.selectedPlan.name) {
          case "базовий захист":
            return `${hubPriceWithMontage + this.leadInfo.month.price} ₴`;
          case "максимальный захист":
            return `${
              hubPriceWithMontage + detectorsPriceWithMontage + abonPrice
            } ₴`;
          default: 
            return 'Спробуйте пізніше'
        }
      }
      return "Оберіть пакет та кількість місяців для підключення";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";
.constructor {
  &__total {
    margin-bottom: 24px;
    padding: 40px 135px;
    padding-top: 40px;
    background-color: $mainColor;
    color: $darkColor;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;
    &-error {
      font-size: 24px;
      text-align: center;
    }
    @include screen(768px) {
      padding: 40px;
    }
    &-title {
      margin-bottom: 20px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include screen(968px) {
        justify-content: space-evenly;
      }
      svg {
        @include screen(968px) {
          width: 64px;
          height: 64px;
        }
        @include screen(768px) {
          display: none;
        }
      }
    }
    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      font-size: 48px;
      line-height: 62px;
      font-weight: 400;
      &--empty {
        width: 300px;
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
      }
    }
    &-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin: 0 auto;
      width: 100%;
      max-width: 300px;
      height: 60px;
      font-size: 24px;
      line-height: 24px;
      border: 1px solid transparent;
      border-radius: 57px;
      background-color: $darkColor;
      color: $mainColor;
      transition: all 0.4s ease;
      cursor: pointer;
      @include screen(768px) {
        font-size: 20px;
      }
      &:hover {
        color: $darkColor;
        background-color: transparent;
        border: 1px solid $darkColor;
      }
    }
  }
}
</style>