export const getCities = {
  created() {
    this.getCities();
  },
  data() {
    return {
      cities: [],
    };
  },
  methods: {
    getCities() {
      this.axios
        .get(`${this.$domain}/cities`)
        .then((response) => (this.cities = response.data.data))
        .catch((error) => console.log(error));
    },
  },
};

export const declensionMonth = {
  methods: {
    declensionMonth(month) {
      switch (month) {
        case 1:
          return "місяць";
        case 3:
          return "місяця";
        case 6:
        case 12:
          return "місяців";
      }
    },
  },
};

export const getPackagesWithDurations = {
  created() {
    this.getPackagesWithDuration();
  },
  data() {
    return {
      packages: [],
      subscriptionDuration: [],
    };
  },
  methods: {
    getPackagesWithDuration() {
      this.axios
        .get(`${this.$domain}/packages`)
        .then((response) => {
          this.packages = response.data.data.packages;
          this.subscriptionDuration = response.data.data.durations;
          localStorage.setItem(
            "duration",
            JSON.stringify({
              duration: response.data.data.durations,
            })
          );
        })
        .catch((error) => console.log(error));
    },
  },
};

export const makeLead = {
  methods: {
    makeLead(leadInfo) {
      this.check = false;
      if (this.fields.length || leadInfo.phone.length !== 13) {
        this.text = true;
        this.check = true;
        return;
      }
      this.axios
        .post(`${this.$domain}/lead`, {
          city_id: leadInfo.city.id,
          client: {
            name: leadInfo.name,
            phone: leadInfo.phone.slice(1),
          },
          object: {
            type: leadInfo.typeObject,
          },
          own_equipment: leadInfo.existBrand,
          ...this.$route.query,
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.$router.push("/completion");
          }
        })
        .catch((error) => console.log(error));
    },
  },
};

export const generatePackage = {
  methods: {
    generatePackage(leadInfo) {
      this.check = false;
      if (this.fields.length) {
        this.text = true;
        this.check = true;
        return;
      }
      localStorage.setItem(
        "packageInfo",
        JSON.stringify({
          ...leadInfo.selectedPlan,
          count: leadInfo.roomsWithWindows,
          building: {
            totalFloors: leadInfo.totalFloors,
            currentFloor: leadInfo.floor,
          },
          duration: {
            monthly: leadInfo.month.price,
            id: leadInfo.month.id,
            count: leadInfo.month.duration,
            maxMonth: JSON.parse(localStorage.getItem("duration")).duration,
          },
          cityID: leadInfo.city.id,
          typeObject: leadInfo.typeObject,
          sum: this.getTotal,
        })
      );
      this.$router.push("/package");
    },
  },
};

export const checkout = {
  methods: {
    checkout(typePayment, packageInfo, leadInfo) {
      this.check = true;
      if (this.inProgress) return;

      this.checkoutSpinner = true;
      if (typePayment === "online") {
        this.$emit("input", "сплатити онлайн");
      } else {
        this.$emit("input", typePayment);
      }
      if (this.fields.length) {
        this.text = true;
        this.checkoutSpinner = false;
        this.$emit("onError");
        this.$emit("input", "");
        this.type = "";

        return;
      }
      let roomsCount = packageInfo.count;
      if (packageInfo.name === "базовий захист") {
        roomsCount = 0;
      }

      this.inProgress = true;
      this.axios
        .post(`${this.$domain}/lead/checkout`, {
          package_id: packageInfo.id,
          duration_id: packageInfo.duration.id,
          city_id: packageInfo.cityID,
          client: {
            name: leadInfo.name,
            phone: leadInfo.phone.slice(1),
            email: leadInfo.email,
            secret_word: leadInfo.code,
            date: `${leadInfo.date}, ${leadInfo.timeInterval[0]}-${leadInfo.timeInterval[1]}`,
            trusted_person_name: leadInfo.trustedName,
            trusted_person_phone: leadInfo.trustedPhone.slice(1),
          },
          object: {
            number_of_rooms: roomsCount,
            floor: packageInfo.building.currentFloor,
            number_of_storeys: packageInfo.building.totalFloors,
            type: packageInfo.typeObject,
            address: `${leadInfo.address}, ${leadInfo.addressNumber} ${
              leadInfo.flat ? "кв." + leadInfo.flat : ""
            }`,
          },
          type_payment: typePayment,
          ...this.$route.query,
        })
        .then((response) => {
          if (response.data.result === "success") {
            this.$router.push({ name: "completion" });
          } else {
            document.location.assign(response.data);
          }
          this.inProgress = false;
        });
    },
  },
};
