export const validEmail = {
    methods: {
        validEmail(value) {
            if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
                this.fields.push('email')
                return true;
            }
            return false
        }
    }
}