<template>
  <div class="package__payment">
    <h2 class="subtitle package__payment-title">спосіб оплати</h2>
    <ul class="package__payment-group">
      <li
        @click="checkout('online', packageInfo, leadInfo)"
        class="package__payment-group-item"
        :class="{
          'package__payment-group-item--active':
            modelType === 'сплатити онлайн',
        }"
      >
        <PaymentIcon
          v-if="modelType !== 'сплатити онлайн'"
          :type="'сплатити онлайн'"
        />
        <Spinner v-else :checkout="true" />
        <span class="package__payment-type">{{
          checkoutSpinner && modelType === "сплатити онлайн"
            ? "зачекайте, створюємо документи"
            : "сплатити онлайн"
        }}</span>
      </li>
      <li
        v-for="payment in payments"
        :key="payment"
        @click="checkout(payment, packageInfo, leadInfo)"
        class="package__payment-group-item"
        :class="{
          'package__payment-group-item--active': modelType === payment,
        }"
      >
        <PaymentIcon v-if="modelType !== payment" :type="payment" />
        <Spinner v-else :checkout="true" />
        <span class="package__payment-type">{{
          checkoutSpinner && modelType === payment
            ? "зачекайте, створюємо документи"
            : payment
        }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import PaymentIcon from "./PaymentIcon.vue";
import Spinner from "@/components/Spinner";
import { checkout } from "@/mixins/API";
export default {
  model: {
    prop: "modelType",
  },
  props: ["modelType", "fields", "packageInfo", "leadInfo"],
  mixins: [checkout],
  components: {
    PaymentIcon,
    Spinner,
  },
  data() {
    return {
      payments: ["готівкою монтажнику"],
      type: "",
      checkoutSpinner: false,
      inProgress: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main";

.package {
  &__payment {
    margin-bottom: 56px;
    padding: 56px 48px 64px 56px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;
    @include screen(809px) {
      padding: 56px 10px 64px;
    }
    &-title {
      margin-bottom: 56px;
    }
    &-group {
      display: grid;
      grid-template-columns: repeat(2, 330px);
      justify-content: center;
      transition: all 0.4s ease-in-out;
      justify-items: center;
      gap: 14px;
      @include screen(809px) {
        grid-template-columns: 1fr;
      }
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0;
        border: 1px solid $secondaryColor;
        max-width: 330px;
        width: 100%;
        border-radius: 38px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        svg path {
          transition: fill 0.4s ease-in-out;
        }
        &:hover {
          border-color: $mainColor;
          > svg path {
            fill: $mainColor;
          }
        }
        &--active {
          background-color: $mainColor;
          color: $darkColor;
          border-color: $mainColor;
          svg path {
            fill: $darkColor;
          }
          &:hover {
            svg path {
              fill: $darkColor;
            }
          }
        }
        @include screen(809px) {
          padding: 40px 10px;
          &:first-child {
            svg {
              width: 100%;
              height: 60px;
            }
          }
          &:not(:first-child) {
            svg {
              width: 60%;
              height: 60px;
            }
          }
        }
        @include screen(458px) {
          padding: 20px 10px;
        }
      }
    }
    &-type {
      padding-top: 26px;
      font-size: 18px;
      text-align: center;
      @include screen(809px) {
        font-size: 14px;
        line-height: 13px;
      }
    }
  }
}
</style>