export const dateTime = {
    methods: {
        currentMonth(month) {
            if (month.toString().length === 1) {
                return `0${month}`
            } else {
                return month
            }
        },
        disabledCalendarDates(date) {
            return date.setHours(15) < new Date();
        },
    },
    computed: {
        startTime() {
            const now = new Date();
            return this.workTime ? `${now.getHours() + 3}:00` : '9:00'
        },
        today() {
            const now = new Date()
            return `${now.getDate()}.${this.currentMonth(now.getMonth() + 1)}.${now.getFullYear()}`
        },
        currentDate() {
            return this.today === this.leadInfo.date;
        },
        workTime() {
            const now = new Date();
            return this.currentDate && now.getHours() >= 9 && now.getHours() < 15
        },
        tomorrow() {
            const now = new Date()
            const tomorrow = `${now.getDate() + 1}.${this.currentMonth(now.getMonth() + 1)}.${now.getFullYear()}`
            return this.leadInfo.date === tomorrow
        }
    }
}