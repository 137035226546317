<template>
  <section class="gratitude">
    <div class="container">
      <div class="gratitude__content">
        <div class="gratitude__img">
          <img
            :src="require('@/assets/images/gratitude.png')"
            alt="складання пазлу в честь підключення"
          />
        </div>
        <h1 class="gratitude__title">
          дякуємо <br />
          за підключення!
        </h1>
        <p class="gratitude__info">
          найближчим часом наш менеджер зв’яжеться з вами
        </p>
        <div class="download">
          <p class="download__title">Завантажуйте додаток MySheriff</p>
          <div class="download__links">
            <div class="download__item">
              <a
                class="download__link"
                href="https://apps.apple.com/ua/app/mysheriff-особистий-кабінет/id1589945728"
                ><i class="fab fa-app-store" style="font-size: 40px"></i
              ></a>
              <span class="download__link-title">iOS</span>
            </div>
            <div class="download__item">
              <a
                class="download__link"
                href="https://play.google.com/store/apps/details?id=com.sheriff.app"
              >
                <i class="fab fa-google-play" style="font-size: 40px"></i
              ></a>
              <span class="download__link-title">Android</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => (window.location.href = "https://sheriff.com.ua/"), 4000);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_main.scss";

.gratitude {
  padding: 87px 0 103px 0;
  @include screen(768px) {
    padding: 32px 0;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px;
    border: 1px solid $secondaryColor;
    border-radius: $borderRadius;
    @include screen(576px) {
      padding: 32px;
    }
  }
  &__img {
    margin-bottom: 50px;
  }
  &__title {
    margin-bottom: 36px;
    font-size: 64px;
    line-height: 83px;
    font-weight: 400;
    text-align: center;
    @include screen(768px) {
      font-size: 32px;
      line-height: 40px;
    }
    @include screen(576px) {
      font-size: 22px;
      line-height: 40px;
    }
  }
  &__info {
    max-width: 780px;
    text-align: center;
    font-size: 24px;
    line-height: 31px;
    @include screen(768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.download {
  &__title {
    padding-top: 64px;
    font-size: 32px;
    margin-bottom: 32px;
  }
  &__links {
    display: flex;
    gap: 30px;
    justify-content: center;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  &__link {
    color: #fce500;
    &-title {
      padding-top: 16px;
    }
  }
}
</style>
